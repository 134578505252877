import { NavLink } from 'react-router-dom';
import logo from '../LOGO.png';

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light ms-2 me-2">
      <div className="container-fluid">
        {/* <i className="bi bi-square-fill text-primary me-2 mt-4"></i> */}
        <NavLink className="navbar-brand fs-2" to="/">
          <img className="logo" src={logo} alt="" />
          <strong>Diptish Gohane</strong>
        </NavLink>
        
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 fw-bold">
            <li className="nav-item ">
              <NavLink className="nav-link fs-6 me-3 " activeClassName="active" exact to="/">
                About Me  
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link fs-6 me-3" activeClassName="active" to="/resume">
                Resume
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link fs-6 me-3" activeClassName="active" to="/project">
                Project
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link fs-6 me-3" activeClassName="active" to="/contact">
                Contact
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link fs-6 me-3" activeClassName="active" to="/certificate">
                Certificates
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
